





























import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { BookmarksViewModel } from '../viewmodels/bookmarks-viewmodel'

@Observer
@Component({
  components: {
    'post-list': () => import('./components/likes/post-list.vue'),
    'post-grid': () => import('./components/likes/post-grid.vue'),
  },
})
export default class Bookmark extends Vue {
  @Provide() vm = new BookmarksViewModel()
  @Watch('$route', { immediate: true }) tabChange(val) {
    const hash = this.$router.currentRoute.hash
    if (hash) {
      if (hash === '#bookmark') {
        setTimeout(() => {
          document.getElementById(`list-bookmark`)?.scrollIntoView({ behavior: 'smooth' })
        }, 300)
      }
    }
  }
  destroyed() {
    this.vm.destroy()
  }
}
